<template>
  <a-card :bordered="false">
    <div>
      <a-row>
        <a-col :span="$mq === 'mobile' ? 24 : 10">
          <a-form :label-col="{ span: $mq === 'mobile' ? 10 : 4 }" :wrapper-col="{ span: $mq === 'mobile' ? 24 : 12 }">
            <a-form-item>
              <a-input-search v-model="value" placeholder="ID hoặc Tên nhân sự" enter-button @search="onSearchStaff" />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="14"></a-col>
      </a-row>
      <a-row>
        <a-table
          :loading="loading"
          :columns="columns"
          :dataSource="dataSource"
          bordered
          :rowKey="(record, index) => index"
          :pagination="false"
        >
          <span slot="workShiftToday" slot-scope="record" v-html="record.workShiftToday"></span>
          <span slot="workShiftTomorrow" slot-scope="record" v-html="record.workShiftTomorrow"></span>
          <span slot="workShiftAfterTomorrow" slot-scope="record" v-html="record.workShiftAfterTomorrow"></span>
        </a-table>
        <pagination
          v-show="totalPage > 0"
          :total="totalPage"
          :page.sync="currentPage"
          :limit.sync="pageSize"
          @pagination="onSearchStaff"
        />
      </a-row>
    </div>
  </a-card>
</template>

<script>
import { RepositoryFactory } from '@/api/RepositoryFactory'
import Pagination from '@/components/Pagination'
const StaffRepo = RepositoryFactory.get('staff')
var moment = require('moment')

export default {
  components: {
    Pagination
  },
  data() {
    return {
      value: undefined,
      dataSource: [],
      loading: false,
      dateNow: moment().format('DD/MM/YYYY'),
      currentPage: 1,
      pageSize: 10,
      totalPage: 0,
      columns: [
        {
          title: 'Mã ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Tên nhân sự',
          dataIndex: 'staffName',
          key: 'staffName'
        },
        {
          title: 'Vị trí',
          dataIndex: 'staffType',
          key: 'staffType'
        },
        {
          title: 'Salon đang làm việc',
          dataIndex: 'salonName',
          key: 'salonName'
        },
        {
          title: 'Ca làm hôm nay',
          key: 'workShiftToday',
          scopedSlots: {
            customRender: 'workShiftToday'
          }
        },
        {
          title: 'Ca làm ngày mai',
          key: 'workShiftTomorrow',
          scopedSlots: {
            customRender: 'workShiftTomorrow'
          }
        },
        {
          title: 'Ca làm ngày kia',
          key: 'workShiftAfterTomorrow',
          scopedSlots: {
            customRender: 'workShiftAfterTomorrow'
          }
        }
      ]
    }
  },
  methods: {
    async onSearchStaff() {
      try {
        this.loading = true

        console.log('value', this.value)
        console.log('date', this.dateNow)
        console.log('currentPage', this.currentPage)
        console.log('pageSize', this.pageSize)

        var res = await StaffRepo.getWorkTimeInfo(this.value, this.dateNow, this.currentPage, this.pageSize)

        if (res === null || res.results.length === 0) {
          this.$message.warn('Không có thông tin!', 2)
          return
        }

        this.dataSource = res.results
        this.totalPage = res.total
      } catch (error) {
        this.$message.error(error, 2.5)
      } finally {
        this.loading = false
      }
    }
  },
  created() {}
}
</script>

<style scoped>
.d-value {
  display: block;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.d-label {
  display: block;
  text-align: center;
}
.d-info-card {
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-radius: 2px;
  height: 15;
  line-height: 15px;
  text-align: center;
  box-shadow: 0px 1px 2px;
}
.d-tag-info-card {
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  border-radius: 2px;
  width: 400px;
  min-height: 100px;
  height: auto;
  box-shadow: 0px 1px 2px;
  margin-left: 30px;
}
.d-button {
  margin-right: 20px !important;
  display: inline;
}
.d-group-button {
  display: inline;
}
/* .d-all-button {
  /* color: white;
  background-color: #1890ff !important; *
} */
.d-salon-button {
  border: 1px solid orange !important;
}
.d-online-button {
  border: 1px solid green !important;
}
.d-button-focus:focus {
  background-color: #d6eafc !important;
}
.d-body-container {
  margin-top: 30px;
}

.d-card {
  border: 1px solid #e8e8e8 !important;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 1px 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.d-online-booking-icon {
  width: 20px;
  height: 20px;
  color: white;
  font-weight: bold;
  background-color: black;
  text-align: center;
  border-radius: 50%;
  float: right;
}
.d-title-card {
  font-weight: bold;
}
.d-subtitle-card {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
}
.d-service-label {
  font-weight: bold;
  margin: 5px;
}
.d-service-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  background-color: #f2f2f2;
}
.d-service-name {
  font-style: italic;
}
.d-service-value {
  font-style: italic;
}
.d-service-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold !important;
  margin: 5px;
}
.d-button-view-detail {
  color: white;
  background-color: green !important;
  float: right;
}
.d-button-view-detail-product-return {
  color: white;
  background-color: black !important;
  float: right;
}
.d-card-bill-detail {
  margin: 10px;
}
.d-loading-indicator {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.d-group-customer-info {
  margin-bottom: 10px;
}

.d-group-radio {
  text-align: center;
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.d-pagination {
  text-align: center;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

._img {
  display: flex;
}
.responsive {
  padding: 0 6px;
  width: 25%;
}

.customer-tags-container {
  border: 1px solid #333;
  padding: 5px;
}
.customer-tag-title {
  margin-left: 30px;
  color: #808080;
  margin-bottom: 10px;
}

.customer-tag-item {
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
